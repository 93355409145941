<template>
  <div>
    <div class="chat-data p-0 chat-data-right">
      <div class="chat-content scroller" style=" max-height: 200px; ">
        <template v-for="(item,index) in messages">
          <div v-if="item.fromSystem" class="chat chat-left">
            <div class="chat-user" style="padding-top: 9px;">
              <span class="chat-time mt-1">{{ item.senderName }}</span>
            </div>
            <div class="chat" :key="index">
              <div class="chat-detail">
                <div class="chat-message" style="background: #ffb822 !important">
                  <p>{{ item.message }}</p>
                </div>
              </div>
            </div>
          </div>
          <div v-else-if="(item.fromHost && isHost) || (!item.fromHost && !isHost)">
            <div class="chat-user" style="padding-top: 9px;">
              <span class="chat-time mt-1">{{ item.senderName }}</span>
            </div>
            <div class="chat" :key="index">
              <div class="chat-detail">
                <div class="chat-message">
                  <p>{{ item.message }}</p>
                </div>
              </div>
            </div>
          </div>
          <div v-else class="chat chat-left" :key="index">
            <div class="chat-user" style="padding-top: 9px;">
              <span class="chat-time mt-1">{{ item.senderName }}</span>
            </div>
            <div class="chat-detail">
              <div class="chat-message" style="background: rgb(62 182 29) !important; color:white;">
                <p>{{ item.message }}</p>
              </div>
            </div>
          </div>
        </template>
      </div>
      <div class="chat-footer p-3 bg-white">
        <form class="d-flex align-items-center" action="javascript:void(0);">
          <div class="chat-attagement d-flex">
            <a href="javascript:void(0)" @click="openFileInput">
              <i class="fa fa-paperclip pr-3" aria-hidden="true"></i>
            </a>
            <input type="file" ref="fileInput" style="display: none;" @change="onFileChanged" />
          </div>
          <input type="text" class="form-control mr-3" :placeholder="$t('Message')+'...'" v-model="message" @keypress="typing">
          <button type="submit" @click="sendMessageToParent"
                  class="btn btn-primary d-flex align-items-center p-2">
            <i class="fas fa-paper-plane" aria-hidden="true"></i>
            <span class="d-none d-lg-block ml-1"> {{$t('Send')}}</span>
          </button>
        </form>
      </div>
      <div id="successfulFileSignal" @click="sendSuccessfulFileSignal()" />
    </div>
  </div>
</template>
<script>
  import { fileUploadConfirmation } from '../../../helpers/swalConfirmations'
  export default {
    name: 'OmChatComponent',
    props: {
      messages: {},
      isHost: Boolean,
      hostName: String,
      guestName: String
    },
    data() {
      return {
        message: "",
      };
    },
    computed: {
      username: function () {
        return (this.isHost == true ? this.hostName : this.guestName);
      }
    },
    methods: {
      openFileInput() {
        this.$refs.fileInput.click();
      },
      isNullOrEmpty(str) {
        var returnValue = false;
        if (!str
          || str == ' '
          || str == null
          || str === 'null'
          || str === ''
          || str === '{}'
          || str === 'undefined'
          || str.length === 0) {
          returnValue = true;
        }
        return returnValue;
      },
      formatSystemMessage(msg) {
        return msg.replace("Guest", this.guestName).replace("Host", this.hostName);
      },
      sendMessageToParent() {
        let $this = this;

        if (this.isNullOrEmpty(this.message))
          return;

        $this.$store.state.Meeting.meethub.invoke("SendTextMessage", { message: this.message, senderName: this.username });
        $this.playNotificationSound();
        $this.message = "";
      },
      playNotificationSound() {
        const audio = new Audio("http://gget.it/u1urz3zh/popsound.mp3");
        audio.play();
      },
      typing: function (evt) {
        evt = (evt) ? evt : window.event;

        var charCode = (evt.which) ? evt.which : evt.keyCode;

        if (charCode == 13) {
          evt.preventDefault();
          this.sendMessageToParent();
          this.message = "";
        }

      },
      getMessageCssClass(message) {

        let returnClass = "kt-chat__message ";

        if (message.fromSystem == true) {
          return returnClass;
        }
        else {
          if (this.isHost) {
            if (message.fromHost) {
              returnClass += "kt-chat__message--right ";
            }
          }
          else if (this.isHost == false) {
            if (message.fromHost == false) {
              returnClass += "kt-chat__message--right ";
            }
          }
        }

        return returnClass;
      },
      getMessageColorClass(message) {

        let returnClass = "kt-chat__text ";

        if (message.fromSystem == true) {
          returnClass += "kt-bg-warning ";
        }
        else {
          if (this.isHost) {
            if (message.fromHost) { returnClass += "kt-bg-light-success "; }
            else { returnClass += "kt-bg-light-brand "; }
          }
          else if (this.isHost == false) {
            if (message.fromHost == false) { returnClass += "kt-bg-light-success "; }
            else { returnClass += "kt-bg-light-brand "; }
          }
        }

        return returnClass;
      },
      onFileChanged(event) {
        fileUploadConfirmation(event, this.$parent.meeting.id, this.isHost, this.username);
      },
      sendSuccessfulFileSignal() {
        this.$store.state.Meeting.meethub.invoke("SendFile");
      }
    },
  }
</script>
